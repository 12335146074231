import { InfoCircleOutlined } from "@ant-design/icons";
import { DayPicker } from "react-day-picker";
import Button from "../../atoms/Button";
import FormHeader from "../../atoms/FormHeader";
import styles from "./AppointmentCalendar.module.scss";
// import { Calendar, momentLocalizer } from "react-big-calendar";
// import moment from "moment";
// import "moment/locale/it.js";
// import { useMemo } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { it } from "date-fns/locale";
import { ReactNode, useEffect, useState } from "react";
import { useLocation, useRouteLoaderData } from "react-router-dom";
import { Socket } from "socket.io-client";
import { hoursSlot } from "../../../utils/hoursSlot";
import { trpc } from "../../../utils/trpc";
import Input from "../../atoms/Input";
import routes from "routes";
import { time } from "utils/general";
import { setInfo } from "store/slices/infoSlice";
import { useAppDispatch } from "customHooks/reduxHooks";
import { analyticsService } from "../../../utils/analyticsService";
import { raiseException } from "../../../utils/raiseException";
import { getNormalizedDate, getNormalizedTime } from "../../../utils/dateTime";

interface Props {
  closeModal: () => void;
  className?: string;
  firstAppointment?: boolean;
  subtitle?: string | ReactNode;
  patientId?: string;
  chatId: string;
  preselectDay?: Date;
  preselectHour?: string;
  insideForm?: boolean;
  appointmentToModify?: any;
  onMove?: () => void;
}

export const AppointmentCalendar = ({
  closeModal,
  className = "",
  firstAppointment,
  subtitle,
  patientId,
  chatId,
  preselectDay,
  preselectHour,
  insideForm,
  appointmentToModify,
  onMove,
}: Props) => {
  const socket = useRouteLoaderData("root") as Socket;
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [selectedDay, setSelectedDay] = useState<any[]>(
    preselectDay
      ? [{ date: new Date(preselectDay), endTime: new Date(preselectDay) }]
      : [{ date: new Date(), endTime: new Date() }]
  );
  const [selectedChat, setSelectedChat] = useState<string | undefined>(chatId);
  const [selectedHour, setSelectedHour] = useState(
    preselectHour ? preselectHour : ""
  );
  const [busyDay, setBusyDay] = useState<any[]>([]);
  const [hoursAppointment, setHoursAppointment] = useState<string[]>([]);
  const [showGoDownArrow, setShowGoDownArrow] = useState(true);
  const [patientOptions, setPatientOptions] = useState<any>();
  const [selectedPatient, setSelectedPatient] = useState<string | undefined>(
    patientId
  );
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [showHourDate, setShowHourDate] = useState(false);

  const myChats = trpc.mainService.chat.getChats.useQuery().data;

  const patient = trpc.mainService.therapist.getPatient.useQuery(
    {
      patientId: patientId!,
    },
    {
      enabled: !!patientId,
    }
  );

  const getPreferences =
    trpc.mainService.therapist.getPreferences.useQuery().data;

  const getAppointments = trpc.mainService.appointment.getAppointments.useQuery(
    {}
  ).data;

  const createAppointment =
    trpc.mainService.appointment.createAppointment.useMutation();

  const createWidgetMessage =
    trpc.mainService.appointment.createWidgetMessage.useMutation();

  const deleteAppointment = trpc.mainService.appointment.deleteAppointment.useMutation();

  const myPatient =
    trpc.mainService.therapist.getMyPatients.useQuery().data?.patients;

  const modifyAppointment =
    trpc.mainService.appointment.moveAppointment.useMutation();

  const selectDay = (day: Date) => {
    const daysWithAppointment = busyDay
      ?.filter((a) => a.date.toDateString() === day.toDateString())
      .filter((a) => a.status !== "moved")
      .filter((a) => a.status !== "cancelled");

    if (daysWithAppointment?.length > 0) {
      setSelectedDay(daysWithAppointment);
    } else {
      setSelectedDay([{ date: day, endTime: day, startTime: null }]);
    }
  };

  const selectHour = async () => {
    const day = selectedDay[0].date.toDateString();
    const date = getNormalizedTime(new Date(`${day} ${selectedHour}:00`));

    if (selectedHour !== "") {
      try {
        const { appointment } = await createAppointment.mutateAsync({ patientId: selectedPatient!, startTime: date });
        await sendAppointmentWidget(appointment, 'create');

        if (patient?.data?.patient.isFirstAppointment) {
          analyticsService({ 'event': 'free_session_booked' });
        }
      } catch (error: any) {
        raiseException(error);
        if (error?.shape?.errorCode === "AppointmentInvalidStartTime") {
          dispatch(
            setInfo({
              text: "L'ora di inizio selezionata è troppo vicina",
              variant: "error",
            })
          );
        } else {
          dispatch(setInfo({ text: "Errore inaspettato", variant: "error" }));
        }
      }
    }
  };

  const onModifyAppointment = async () => {
    const day = selectedDay[0].date.toDateString();
    const startTime = getNormalizedTime(new Date(`${day} ${selectedHour}:00`));

    try {
      const { appointment } = await modifyAppointment.mutateAsync({startTime, appointmentId: appointmentToModify.id!});
      await sendAppointmentWidget(appointment, 'modify');
    } catch (error: any) {
      raiseException(error);
      console.error("ERROR:", error?.shape?.message);
    }
  };

  const sendAppointmentWidget = async (appointment: any, type: string) => {
    try {
      if (selectedChat && appointment?.id) {
        const widget: any = await createWidgetMessage.mutateAsync({
          chatId: selectedChat,
          type: "widget",
          appointmentId: appointment?.id
        });

        if (widget?.appointmentData?.id) {
          socket.emit(
            "sendWidgetMessage",
            widget?.newMessage,
            widget?.appointmentData,
            widget?.chat
          );
          if (type === 'modify') {
            if (onMove) {
              onMove();
            }
          }
          closeModal();
        }
      }
    } catch (error: any) {
      try {
        await deleteAppointment.mutateAsync({appointmentId: appointment?.id});
      } catch {}
        dispatch(
          setInfo({
            text: "Si è verificato un errore, prova a creare nuovamente l'appuntamento.",
            variant: "error",
          })
        );
        closeModal();
    }
  }

  useEffect(() => {
    if (busyDay && selectedDay) {
      selectDay(selectedDay[0].date);
    }
  }, [busyDay]);

  useEffect(() => {
    if (createAppointment.status === "loading") {
      setOpenLoadingModal(true);
    } else {
      setOpenLoadingModal(false);
    }
  }, [createAppointment]);

  useEffect(() => {
    if (myChats) {
      const patientChat = myChats.chats.find(
        (c) => c.patient?.id === selectedPatient
      );
      setSelectedChat(patientChat?.id);
    }
  }, [myChats, selectedPatient]);

  useEffect(() => {
    if (getAppointments) {
      const appointmentsDate = getAppointments.appointments
        .filter(
          (a) =>
            (a.status === "ready" || a.status === "waiting_for_confirm") &&
            a.id !== appointmentToModify?.id
        )
        .map((a) => {
          return {
            date: getNormalizedDate(a.startTime),
            startTime: getNormalizedDate(a.startTime),
            endTime: getNormalizedDate(a.endTime),
            patient: `${a.patient?.given_name} ${a.patient?.family_name}`,
            status: a.status,
          }});
      setBusyDay(appointmentsDate);
    }
  }, [getAppointments]);

  useEffect(() => {
    if (getPreferences) {
      const { preferences } = getPreferences;
      const { timeSlots } = preferences!;
      const hoursInSlot = timeSlots?.map((t) => hoursSlot.filter((h) => h)[0].hours)
        .reduce((a, b) => a.concat(b));
      setHoursAppointment(
        hoursSlot.map((i) => i.hours).reduce((a, b) => a.concat(b))
      );
    }
  }, [getPreferences]);

  useEffect(() => {
    if (myPatient) {
      setPatientOptions(
        [{ name: "Seleziona un paziente", value: "" }].concat(
          myPatient?.map((p) => ({
            name: `${p.given_name} ${p.family_name}`,
            value: p.id,
          }))
        )
      );
      // if (patientId === undefined) {
      //   setSelectedPatient(myPatient[0].id);
      // }
    }
  }, [myPatient]);

  // useEffect(() => {
  //   if (appointmentToModify) {
  //     setSelectedDay([{ date: new Date(appointmentToModify.startTime) }]);
  //   }
  // }, [appointmentToModify]);

  return (
    <div className={`${styles.appointmentCalendar} ${className}`}>
      {!insideForm && (
        <FormHeader
          title="Seleziona uno slot da inviare al paziente"
          closeModal={closeModal}
          closeButton
        />
      )}
      {location.pathname === routes.Appointments.path && !insideForm && (
        <Input
          select
          options={patientOptions}
          onChange={(e) => {
            setSelectedPatient(e.target.value);
          }}
          value={selectedPatient}
        />
      )}
      {(selectedPatient || patientId) && (
        <div className={styles.bottomPart}>
          <div className={styles.subtitle}>{subtitle}</div>
          <div className={styles.calendarContainer}>
            <div
              className={`${styles.dateContainer} ${
                showHourDate && styles.hideContainer
              }`}
            >
              <DayPicker
                locale={it}
                mode="multiple"
                selected={selectedDay.map((d) => d.date)}
                disabled={(date) => {
                  const startOfTheDay = new Date();
                  const calendarDate = new Date(date);
                  startOfTheDay.setHours(0);
                  calendarDate.setHours(0);
                  startOfTheDay.setMinutes(0);
                  calendarDate.setMinutes(0);
                  startOfTheDay.setSeconds(0);
                  calendarDate.setSeconds(0);
                  startOfTheDay.setMilliseconds(0);
                  calendarDate.setMilliseconds(0);
                  return +getNormalizedDate(date) < +getNormalizedDate(startOfTheDay);
                }}
                // selected={busyDay
                //   .filter((d) => d.status !== "cancelled")
                //   .filter((d) => d.status !== "moved")
                //   .map((d) => d.date)}
                onDayClick={(date) => {
                  selectDay(date);
                  setShowHourDate(true);
                }}
              />
            </div>
            <div
              className={`${styles.hoursContainer} ${
                !showHourDate && styles.hideContainer
              }`}
            >
              <div className={styles.selectedDay}>
                {selectedDay[0].date.toLocaleDateString("it", {
                  day: "2-digit",
                  weekday: "long",
                  month: "long",
                })}
              </div>
              <div
                className={styles.hoursWrapper}
                onScroll={(e) => {
                  setShowGoDownArrow(
                    e.currentTarget.scrollTop !==
                      e.currentTarget.scrollHeight -
                        e.currentTarget.offsetHeight
                  );
                }}
              >
                {hoursAppointment.map((h) => {
                  const day = new Date(
                    `${selectedDay[0].date.toDateString()} ${h}`
                  );
                  const hour = `${h.split(":")[0]}:${h.split(":")[1]}`;
                  const patientName = selectedDay.find(
                    (d) => d.date.toTimeString().split(" ")[0] === h
                  )?.patient;

                  return selectedDay
                    .filter((d) => d.status !== "moved")
                    .filter((d) => d.status !== "cancelled")

                    .find((d) => {
                      if (day < new Date()) {
                        return d;
                      }

                      let appointmentDuration = 50;
                      if (
                        patient?.data?.patient.isFirstAppointment ||
                        firstAppointment
                      ) {
                        appointmentDuration = 50;
                      } else if (
                        patient?.data?.patient?.therapyType === "couple"
                      ) {
                        appointmentDuration = 60;
                      }

                      // if there is an appointment in this slot
                      if (d.startTime) {
                        // it blocks the slots before an appointment
                        if (
                          +getNormalizedDate(day) >
                          +getNormalizedDate(d.startTime) -
                              time.ONE_MINUTE_IN_MILLIS * appointmentDuration &&
                          +getNormalizedDate(day) < +getNormalizedDate(d.startTime)
                        ) {
                          return d;
                        } else {
                          // it blocks the slots for the appointment
                          return (
                            +getNormalizedDate(d.date) <= +getNormalizedDate(day) &&
                            +getNormalizedDate(d.endTime) > +getNormalizedDate(day)
                          );
                        }
                      }

                      // it blocks the past slots + 5 minutes imminent slots
                      return (
                        +getNormalizedDate(day) - time.ONE_MINUTE_IN_MILLIS * 5 <=
                        +getNormalizedDate(d.date)
                      );
                    }) ? (
                    <Button key={hour} className={styles.bookedBtn} disabled>
                      <div>{hour}</div>
                      <div>{patientName}</div>
                    </Button>
                  ) : (
                    <Button
                      className={`${styles.freeHourBtn} ${
                        selectedHour === hour && styles.hourSelected
                      }`}
                      key={hour}
                      variant="secondary"
                      onClick={() => {
                        setSelectedHour(hour);
                      }}
                    >
                      {hour}
                    </Button>
                  );
                })}
              </div>
              {showGoDownArrow && (
                <div className={styles.goDown}>
                  <ChevronDownIcon />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {(firstAppointment || patient.data?.patient.isFirstAppointment) && (
        <div className={styles.firstAppointmentMessage}>
          <InfoCircleOutlined /> Questo primo incontro sarà gratuito
        </div>
      )}
      <div
        className={`${styles.btnWrapper} ${
          !showHourDate && styles.hideContainer
        }`}
      >
        <Button onClick={() => closeModal()} variant="tertiary">
          Annulla
        </Button>
        <Button
          disabled={openLoadingModal}
          onClick={() =>
            appointmentToModify ? onModifyAppointment() : selectHour()
          }
        >
          Conferma e invia slot
        </Button>
      </div>
    </div>
  );
};
