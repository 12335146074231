import { Input } from "antd";
import { useRef, useState } from "react";
import { trpc } from "../../../utils/trpc";
import Button from "../../atoms/Button";
import FormHeader from "../../atoms/FormHeader";
import Tabs from "../../atoms/Tabs";
import AppointmentCalendar from "../../organisms/AppointmentCalendar";
import styles from "./ModifyAppointmentForm.module.scss";
import { Socket } from "socket.io-client";
import { useRouteLoaderData } from "react-router-dom";
import InfoBox from "components/atoms/InfoBox";
import { raiseException } from "../../../utils/raiseException";
import { setInfo } from "../../../store/slices/infoSlice";
import { useAppDispatch } from "../../../customHooks/reduxHooks";
import { getNormalizedDate } from "../../../utils/dateTime";

interface Props {
  closeModal: () => void;
  onPatientRequestMove?: () => void;
  chatId: string;
  setMessage?: any;
  sendModifyMessage?: (message: string) => void;
  patientId?: string;
  appointment?: any;
  onCancel?: () => void;
  onMove?: () => void;
  onRequestMove?: () => void;
}

export const ModifyAppointmentForm = ({
  closeModal,
  onPatientRequestMove,
  chatId,
  setMessage,
  sendModifyMessage,
  patientId,
  appointment,
  onCancel,
  onMove,
  onRequestMove,
}: Props) => {
  const inputRef = useRef(null);
  const socket = useRouteLoaderData("root") as Socket;
  const dispatch = useAppDispatch();

  const [message, setValue] = useState<string>("");
  const [activeSection, setActiveSection] = useState<"move" | "cancel">("move");

  const userInfo = trpc.mainService.user.getCurrentUserInfo.useQuery().data;

  const askToMove =
    trpc.mainService.appointment.askMoveAppointment.useMutation();

  const cancelAppointment =
    trpc.mainService.appointment.cancelAppointment.useMutation();

  const createWidgetMessage =
    trpc.mainService.appointment.createWidgetMessage.useMutation();

  const deleteAppointment = trpc.mainService.appointment.deleteAppointment.useMutation();

  const showMessage = async () => {
    // const input = inputRef.current!;
    // const { resizableTextArea } = input;
    // const { textArea } = resizableTextArea;
    // const { value } = textArea;
    // sendModifyMessage!(value);
    if (userInfo?.user.role === "patient") {
      askToMove.mutateAsync({ appointmentId: appointment.id }).then((data) => {
        socket.emit(
          "sendTextMessage",
          {
            type: "text",
            content: `&moved ${userInfo.user.given_name} ${
              userInfo.user.family_name
            } ha richiesto di spostare la seduta di ${getNormalizedDate(
              appointment.startTime
            ).toLocaleString("IT", {
              weekday: "long",
              day: "2-digit",
              month: "long",
            })} alle ore ${getNormalizedDate(appointment.startTime).toLocaleString(
              "IT",
              {
                hour: "2-digit",
                minute: "2-digit",
              }
            )} per il seguente motivo:&moved ${message}`,
          },
          chatId
        );
        if (onPatientRequestMove) {
          onPatientRequestMove();
        }
        if (onRequestMove) {
          onRequestMove();
        }
      });
    } else {
      try {
        const widget: any = await createWidgetMessage.mutateAsync({
          chatId,
          type: "widget",
          appointmentId: appointment?.id
        });

        if (widget?.appointmentData?.id) {
          socket.emit(
            "sendWidgetMessage",
            widget?.newMessage,
            widget?.appointmentData,
            widget?.chat
          );
        }
      } catch (error) {
        try {
          await deleteAppointment.mutateAsync({appointmentId: appointment?.id});
        } catch {}
        dispatch(
          setInfo({
            text: "Si è verificato un errore, prova a creare nuovamente l'appuntamento.",
            variant: "error",
          })
        );
      }
    }
    closeModal();
  };

  const onCancelAppointment = () => {
    cancelAppointment
      .mutateAsync({ appointmentId: appointment.id })
      .then((data) => {
        socket.emit(
          "sendTextMessage",
          {
            type: "text",
            content: `&moved ${userInfo?.user.given_name} ${
              userInfo?.user.family_name
            } ha cancellato la seduta di ${getNormalizedDate(
              appointment.startTime
            ).toLocaleString("IT", {
              weekday: "long",
              day: "2-digit",
              month: "long",
            })} alle ore ${getNormalizedDate(appointment.startTime).toLocaleString(
              "IT",
              {
                hour: "2-digit",
                minute: "2-digit",
              }
            )} per il seguente motivo:&moved ${message}`,
          },
          chatId
        );
        if (onCancel) {
          onCancel();
        }
        closeModal();
      })
      .catch((e) => {
        raiseException(e);
        console.error("ERROR", e)
      });
  };

  const patientMoveoveAppointment = (
    <div className={styles.move}>
      <p>
        Indica qui sotto il motivo e altre disponibilità così il terapeuta potrà
        inviarti un nuovo appuntamento.
        <span style={{ fontWeight: 600 }}>
          {" "}
          Questo messaggio verrà inviato in chat al terapeuta.
        </span>
      </p>
      <div className={styles.textArea}>
        <Input.TextArea
          ref={inputRef}
          placeholder="Motivazione..."
          size="middle"
          autoSize={{ minRows: 7, maxRows: 7 }}
          value={message}
          onChange={(e) => {
            e.preventDefault();
            setValue(e.target.value);
          }}
        />
      </div>
      <div className={styles.btnWrapper}>
        <Button
          onClick={closeModal}
          className={styles.cancelBtn}
          variant="secondary"
        >
          Annulla
        </Button>
        <Button
          className={styles.confirmBtn}
          disabled={message.trim() === ""}
          onClick={() => {
            showMessage();
          }}
        >
          {userInfo?.user.role === "patient"
            ? "Richiedi al terapeuta"
            : "Sposta e invia al paziente"}
        </Button>
      </div>
    </div>
  );

  const therapistMoveAppointment = (
    <div className={styles.calendarContainer}>
      <AppointmentCalendar
        insideForm
        className={styles.appointmentCalendar}
        chatId={chatId!}
        closeModal={closeModal}
        patientId={patientId}
        appointmentToModify={appointment}
        onMove={onMove}
        firstAppointment={appointment.isFirstAppointment}
      />
    </div>
  );

  return (
    <div className={styles.form}>
      <FormHeader
        title="Sposta o cancella appuntamento"
        closeModal={closeModal}
        closeButton
      />
      {userInfo?.user.role === "therapist" && (
        <div className={styles.therapistSubtitle}>
          <span>Avvisa sempre il paziente via chat</span> prima di spostare o
          cancellare un appuntamento.
        </div>
      )}
      <Tabs
        className={styles.tabs}
        items={[
          {
            key: 1,
            label: "Sposta",
            active: activeSection === "move",
            content:
              userInfo?.user.role === "patient"
                ? patientMoveoveAppointment
                : therapistMoveAppointment,
          },
          {
            key: 2,
            disabled: appointment.status === "expired",
            label: "Cancella",
            active: activeSection === "cancel",
            content: (
              <div className={styles.move}>
                {userInfo?.user.role === "patient" ? (
                  <p>
                    Indica qui sotto il motivo e altre disponibilità così il
                    terapeuta potrà inviarti un nuovo appuntamento.
                    <span style={{ fontWeight: 600 }}>
                      {" "}
                      Questo messaggio verrà inviato in chat al terapeuta.
                    </span>
                  </p>
                ) : (
                  <p>
                    Scrivi in questo box la motivazione per la cancellazione
                    dell’appuntamento,
                    <span style={{ fontWeight: 600 }}>
                      {" "}
                      questo messaggio verrà inviato nella chat con il paziente.
                    </span>
                  </p>
                )}
                <div className={styles.textArea}>
                  <Input.TextArea
                    placeholder="Motivazione..."
                    size="middle"
                    autoSize={{ minRows: 7, maxRows: 7 }}
                    value={message}
                    onChange={(e) => {
                      e.preventDefault();
                      setActiveSection("cancel");
                      setValue(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.btnWrapper}>
                  <Button
                    onClick={closeModal}
                    className={styles.cancelBtn}
                    variant="secondary"
                  >
                    Annulla
                  </Button>
                  <Button
                    className={styles.confirmBtn}
                    disabled={message.trim() === ""}
                    onClick={(e: any) => {
                      e.preventDefault();
                      onCancelAppointment();
                    }}
                  >
                    Cancella e invia al{" "}
                    {userInfo?.user.role === "patient"
                      ? "terapeuta"
                      : "paziente"}
                  </Button>
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
