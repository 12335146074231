import { CalendarDaysIcon, InformationCircleIcon, VideoCameraIcon, } from "@heroicons/react/24/outline";
import Button from "../../atoms/Button";
import styles from "./AppointmentWidget.module.scss";
import circeShape from "../../../theme/images/circleShape.png";
import trangleShape from "../../../theme/images/triangleShape.png";
import { capitalize } from "../../../utils/capitalize";
import InfoBox from "../../atoms/InfoBox";
import { trpc } from "../../../utils/trpc";
import { useEffect, useState } from "react";
import { raiseException } from "../../../utils/raiseException";
import { getNormalizedDate } from "../../../utils/dateTime";

type Variant = "warn" | "info" | "confirm" | "error" | undefined;

interface Props {
  messageId: number;
  appointment: any;
  isFirstAppointment: null | "x";
  widgetStatus:
    | "waiting_for_confirm"
    | "ready"
    | "moved"
    | "cancelled"
    | "completed"
    | "expired";
  userRole: "patient" | "therapist";
  startTime: string;
  endTime: string;
  onModify: (appointmentId: any, messageId: number) => void;
  onAccept: (appointmentId: string, messageId: number) => void;
  onPatientPayment: () => void;
  onReady: (appointmentId: string) => void;
  createdByPatient?: any;
}

export const AppointmentWidget = ({
  messageId,
  widgetStatus,
  isFirstAppointment,
  createdByPatient,
  appointment,
  startTime,
  endTime,
  userRole,
  onModify,
  onAccept,
  onPatientPayment,
  onReady,
}: Props) => {
  const acceptFreeAppointment =
    trpc.mainService.appointment.acceptFreeAppointment.useMutation();


  const confirmFreeAppointment = () => {
    acceptFreeAppointment
      .mutateAsync({ appointmentId: appointment.id })
      .then((data) => console.log("acceptFreeAppointment DATA:", data))
      .catch((e) => {
        raiseException(e);
        console.log("ERROR:", e)
      });
  };

  const [widgetState, setWidgetState] = useState<any>(null);

  const getWidgetState = (appointmentData: any) => {
    const isExpired = +getNormalizedDate(startTime) <= +getNormalizedDate();
    const getPatientState = () => {
      switch (widgetStatus) {
        case "waiting_for_confirm":
          if (isExpired) {
            return {
              title: "Appuntamento Cancellato",
              text: "L'appuntamento è stato cancellato perché non è stato confermato entro il tempo limite",
            };
          }
          return { buttonText: "Accetta" };
        case "ready":
          return { buttonText: "Collegati" };
        case "completed":
          return { buttonText: "Collegati" };
        case "moved":
          return {
            title: "Appuntamento Spostato",
            text: "Il terapeuta ha spostato questo appuntamento.",
          };
        case "cancelled":
          return {
            title: "Appuntamento Cancellato",
            text:
              appointmentData?.cancelledBy === "patient"
                ? "Hai scelto di cancellare l'appuntamento. Se vuoi puoi accordarti con il terapeuta per scegliere un altro giorno."
                : "Il tuo terapeuta ha scelto di cancellare l'appuntamento. Se vuoi puoi accordarti con lui per scegliere un altro giorno.",
          };
        case "expired":
          return {
            title: "Appuntamento Cancellato",
            text: "L'appuntamento è stato cancellato perché non è stato confermato entro il tempo limite",
          };
        default:
          return {buttonText: "Accetta"};
      }
    };

    const getTherapistState = () => {
      switch (widgetStatus) {
        case "waiting_for_confirm":
          if (isExpired) {
            return {
              title: "Appuntamento Cancellato",
              text: (
                <div>
                  L'appuntamento è stato cancellato perché non è stato confermato
                  entro il tempo limite.{" "}
                  <span
                    onClick={() => {
                      onModify(appointment, messageId);
                    }}
                    style={{textDecoration: "underline", cursor: "pointer"}}
                  >
                  Sposta
                </span>
                </div>
              ),
            };
          }
          if (isFirstAppointment !== null) {
            return {
              buttonText: "Collegati",
              text: "PRIMO APPUNTAMENTO",
              variant: "confirm" as Variant,
            };
          } else {
            return {
              buttonText: "Collegati",
              text: "IN ATTESA DI PAGAMENTO",
              variant: "warn" as Variant,
            };
          }
        case "ready":
          return {
            buttonText: "Collegati",
            text: "PAGATO",
            variant: "confirm" as Variant,
          };
        case "completed":
          return {
            buttonText: "Collegati",
            text: "PAGATO",
            variant: "confirm" as Variant,
          };
        case "moved":
          return {
            title: "Appuntamento Spostato",
            text: "Hai scelto di spostare l'appuntamento.",
          };
        case "cancelled":
          return {
            title: "Appuntamento Cancellato",
            text:
              appointmentData?.cancelledBy === "therapist"
                ? "Hai scelto di cancellare l'appuntamento. Se vuoi puoi accordarti con il paziente per scegliere un altro giorno."
                : "Il paziente ha scelto di cancellare l'appuntamento. Se vuoi puoi accordarti con lui per scegliere un altro giorno.",
          };
        case "expired":
          return {
            title: "Appuntamento Cancellato",
            text: (
              <div>
                L'appuntamento è stato cancellato perché non è stato confermato
                entro il tempo limite.{" "}
                <span
                  onClick={() => {
                    onModify(appointment, messageId);
                  }}
                  style={{textDecoration: "underline", cursor: "pointer"}}
                >
                Sposta
              </span>
              </div>
            ),
          };
        default:
          return {};
      }
    };

    if (createdByPatient) {
      return userRole === "patient" ? getTherapistState() : getPatientState();
    } else {
      return userRole === "patient" ? getPatientState() : getTherapistState();
    }
  };

  const [isCompleted, setIsCompleted] = useState<boolean>();
  const [isPast, setIsPast] = useState<boolean>();
  useEffect(() => {
    setWidgetState(getWidgetState(appointment));
    setIsPast(+getNormalizedDate(appointment.endTime) < +getNormalizedDate());
    setIsCompleted(
      +getNormalizedDate(appointment.endTime) + 1000 * 60 * 60 <
      +getNormalizedDate()
    );
    const interval = setInterval(() => {
      setWidgetState(getWidgetState(appointment));
      setIsCompleted(
        +getNormalizedDate(appointment.endTime) + 1000 * 60 * 60 <
        +getNormalizedDate()
      );
      setIsPast(+getNormalizedDate(appointment.endTime) < +getNormalizedDate());
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [appointment]);
  

  const appointmentDate = () => {
    const date = getNormalizedDate(startTime).toLocaleDateString("it", {
      weekday: "short",
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    const arrayDate = date.split(" ");
    arrayDate[0] = capitalize(arrayDate[0]) + ",";
    arrayDate[2] = capitalize(arrayDate[2]);

    return arrayDate.join(" ");
  };

  const appointmentHour = () => {
    const startDate = getNormalizedDate(startTime).toLocaleString("IT", {
      hour: "2-digit",
      minute: "2-digit",
    })
    const endDate = getNormalizedDate(endTime).toLocaleString("IT", {
      hour: "2-digit",
      minute: "2-digit",
    });

    return startDate + (isFirstAppointment && userRole === "patient" ? '' : ' - ' + endDate);
  };

  return (
    <div className={styles.widget}>
      {isFirstAppointment && <p className={styles.title}>Primo incontro gratuito</p>}
      <div className={styles.dateWrapper}>
        <div className={styles.calendar}>
          <div className={styles.icon}>
            <CalendarDaysIcon />
          </div>
          <div className={styles.date}>
            <div className={styles.day}>{appointmentDate()}</div>
            <div className={styles.hour}>{appointmentHour()}</div>
          </div>
        </div>
        <div className={styles.shapes}>
          <img
            className={styles.circleShape}
            src={circeShape}
            alt="circle shape"
          />
          <img
            className={styles.trangleShape}
            src={trangleShape}
            alt="triangle shape"
          />
        </div>
      </div>
      {!isPast && widgetStatus === "waiting_for_confirm" && (
        <div className={styles.moveRequestLabel}>
          <InformationCircleIcon width={17} />{" "}
          {isFirstAppointment
            ? "In attesa di conferma"
            : "In attesa di pagamento"}
        </div>
      )}
      {!isPast && widgetStatus === "ready" && (
        <div className={styles.paidLabel}>
          <InformationCircleIcon width={17} />{" "}
          {isFirstAppointment ? "Confermato" : "Pagato"}
        </div>
      )}
      {(appointment.status === "waiting_for_confirm" ||
        appointment.status === "ready") &&
        !isCompleted &&
        appointment.moveRequest && (
          <div className={styles.moveRequestLabel}>Richiesto spostamento</div>
        )}
      {widgetState?.buttonText ||
      getWidgetState(appointment)?.buttonText ? (
        <div className={styles.btnWrapper}>
          <Button
            className={styles.firstBtn}
            variant="tertiary"
            onClick={() => onModify(appointment, messageId)}
            disabled={isPast}
          >
            Modifica
          </Button>
          <Button
            className={styles.secondBtn}
            disabled={
              (userRole === "therapist" &&
                !(widgetStatus === "ready" || widgetStatus === "completed") && !createdByPatient) ||
              appointment.moveRequest ||
              isCompleted || (userRole === "patient" && createdByPatient && widgetStatus === "waiting_for_confirm")
            }
            onClick={() => {
              switch (widgetStatus) {
                case "waiting_for_confirm":
                  if (isFirstAppointment !== null) {
                    return onAccept(appointment.id, messageId);
                  }
                  return onPatientPayment();
                case "ready":
                  return onReady(appointment.id);
                case "completed":
                  return onReady(appointment.id);
              }
            }}
            leftIcon={
              userRole === "therapist" ||
              widgetStatus === "ready" ||
              widgetStatus === "completed" || (userRole === "patient" && createdByPatient) ? (
                <VideoCameraIcon />
              ) : null
            }
          >
            {getWidgetState(appointment)?.buttonText ||
              widgetState?.buttonText}
          </Button>
        </div>
      ) : (
        <InfoBox
          className={styles.infoBox}
          variant="warn"
          title={
            widgetState?.title || getWidgetState(appointment)?.title
          }
          text={
            widgetState?.text || getWidgetState(appointment)?.text
          }
        />
      )}
    </div>
  );
};
