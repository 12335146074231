import Button from "components/atoms/Button";
import Tabs from "components/atoms/Tabs";
import { useEffect, useState } from "react";
import { trpc } from "utils/trpc";
import crossedCalendar from "../../../theme/svg/crossed-calendar.svg";
import styles from "./PatientDetail.module.scss";
import AppointmentCard from "components/molecules/AppointmentCard";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import routes from "routes";
import Modal from "../Modal";
import { getNormalizedDate } from "../../../utils/dateTime";

interface Props {
  patient?: any;
  onGoToChat?: any,
  getInformedConsentResponse?: any,
}

export const PatientDetail = ({patient, getInformedConsentResponse, onGoToChat}: Props) => {

  const [appointments, setAppointments] = useState<any[]>([]);
  const [expiredAppointments, setExpiredAppointments] = useState<any[]>([]);
  const [showSurveyAnswers, setShowSurveyAnswers] = useState(false);
  const [survey, setSurvey] = useState<any[]>([]);

  const emptyState = (
    <div className={styles.emptyState}>
      <div className={styles.iconWrapper}>
        <img src={crossedCalendar} alt="crossed calendar" />
      </div>
      <div>
        Non hai appuntamenti in programma.
      </div>
      <Button
        className={styles.emptyStateBtn}
        onClick={() => onGoToChat()}
        variant="secondary"
      >
        Vai alla chat
      </Button>
    </div>
  );

  const getAppointments = trpc.mainService.appointment.getAppointments.useQuery(
    { patientId: patient.id }
  ).data;

  useEffect(() => {
    if (getAppointments) {
      setAppointments(
        getAppointments?.appointments.filter(
          (a) => a.status === "ready" || a.status === "waiting_for_confirm"
        )
      );
      setExpiredAppointments(
        getAppointments.appointments
          .filter((a) => a.status !== "ready")
          .filter((a) => a.status !== "waiting_for_confirm")
      );
    }
  }, [getAppointments]);

  useEffect(() => {
    setSurvey(patient.survey.questionsWithAnswers);
  }, [patient]);

  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [modalChildren, setModalChildren] = useState<any>(null);

  const viewInformedConsent = () => {
    window.open(`${routes.InformedConsentView.path.replace(/\/:[^\/]+$/, '')}/${patient.id}`, '_blank');
  }

  const tabsItems = [
    {
      key: 1,
      label: "Prossime sedute",
      active: true,
      content: (
        <div className={styles.bottomPart}>
          {appointments.length > 0 ? (
            <div style={{ width: "100%" }}>
              <div className={styles.programmedAppointment}>
                {appointments.map((i, index) => {
                  return (
                    <div key={index}>
                      <AppointmentCard
                        appointment={i}
                        role={"therapist"}
                        setModalChildren={setModalChildren}
                        setModalIsVisible={setModalIsVisible}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className={styles.emptyStateContainer}>{emptyState}</div>
          )}
        </div>
      ),
    },
    {
      key: 2,
      label: "Sedute passate",
      //   active: openMainTab === "past",
      content: (
        <div className={styles.bottomPart}>
          {expiredAppointments.length > 0 ? (
            <div className={styles.programmedAppointment}>
              {expiredAppointments.map((a, index) => {
                const appointmentDate = getNormalizedDate(
                  a.startTime
                ).toLocaleDateString("it", {
                  weekday: "short",
                  day: "2-digit",
                  month: "long",
                });

                const appointmentHour = `${getNormalizedDate(
                  a.startTime
                ).toLocaleTimeString("it", {
                  hour: "2-digit",
                  minute: "2-digit",
                })} - ${getNormalizedDate(a.endTime).toLocaleTimeString("it", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}`;

                let appointmentStatus;

                switch (a.status) {
                  case "expired":
                    appointmentStatus = "Cancellato";
                    break;
                  case "cancelled":
                    appointmentStatus = "Cancellato";
                    break;
                  case "completed":
                    appointmentStatus = "Completato";
                    break;
                  case "moved":
                    appointmentStatus = "Spostato";
                    break;
                  default:
                    appointmentStatus = a.status;
                }

                return (
                  <div key={index} className={styles.pastAppointmentTherapist}>
                    <div className={styles[a.status]}>
                      <InfoCircleOutlined /> {appointmentStatus}
                    </div>
                    <div className={styles.patientName}>
                      {a?.patient?.given_name} {a?.patient?.family_name}{" "}
                      {!a.patient && "Paziente Eliminato"}
                    </div>
                    <div className={styles.appointmentDetail}>
                      <div>
                        {appointmentDate} | {appointmentHour}
                      </div>

                      <div className={styles[a.status]}>
                        <InfoCircleOutlined /> {appointmentStatus}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={styles.emptyStateContainer}>{emptyState}</div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.patientDetail}>
      {modalIsVisible && <Modal>{modalChildren}</Modal>}
      {!showSurveyAnswers && (
        <div className={styles.patientCard}>
          <ul className={styles.patientData}>
            <li>
              <span>Genere:</span>{" "}
              {patient?.gender === "male"
                ? "Uomo"
                : patient?.gender === "female"
                ? "Donna"
                : "Altro"}
            </li>
            <li>
              <span>Data di registrazione:</span>{" "}
              {new Date(patient?.createdAt).toLocaleDateString("it", {
                dateStyle: "short",
              })}
            </li>
          </ul>
          {!patient.supervised && (
            <div className={styles.headerTherapistChatButtonWrapper}>
              <Button
                variant="secondary"
                disabled={(patient?.therapyType === 'single' && !getInformedConsentResponse?.informedConsent) ||
                  (patient?.therapyType === 'underage' && !(getInformedConsentResponse?.otherInformedConsentParent && getInformedConsentResponse?.otherInformedConsent && getInformedConsentResponse?.informedConsent)) ||
                  (patient?.therapyType === 'couple' && !getInformedConsentResponse?.informedConsent)}
                onClick={() => viewInformedConsent()}
              >
                Visualizza il consenso informato
              </Button>
            <Button
              onClick={() => setShowSurveyAnswers(true)}
              variant="secondary"
            >
              Vedi risposte questionario
            </Button>
            </div>
          )}
        </div>
      )}
      {!showSurveyAnswers && (
        <div className={styles.patientAppointment}>
          <Tabs
            labelClassName={styles.tabsLabel}
            className={styles.tabs}
            items={tabsItems}
          />
        </div>
      )}
      {showSurveyAnswers && (
        <div className={styles.surveyAnswers}>
          <h5
            className={styles.title}
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <ArrowLeftIcon
              width={20}
              onClick={() => {
                setShowSurveyAnswers(false);
              }}
              style={{
                cursor: "pointer",
              }}
            />{" "}
            Risposte questionario
          </h5>
          <div className={styles.answersList}>
            {survey.map((a, index) => {
              const title =
                a.title?.trim() === "" &&
                a.step &&
                a.numberOfSteps &&
                a.step === a.numberOfSteps
                  ? survey[index - 1].title
                  : a.title;

              let answer: string;

              switch (a.type) {
                case "radio":
                  answer = a.options[a.answer].title;
                  break;
                case "checkbox":
                  answer = a.answer.map((i) => (
                    <div>- {a.options[i].title}</div>
                  ));
                  break;
                default:
                  answer = a.answer;
              }
              if (answer) {
                return (
                  <div key={index} className={styles.answerCard}>
                    <div className={styles.question}>
                      {index + 1}. {title}
                    </div>
                    <div className={styles.answer}>{answer}</div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
};
