import {LoadingOutlined} from "@ant-design/icons";
import {
	ArrowLeftIcon,
	CalendarDaysIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";
import {Collapse, Spin} from "antd";
import FormHeader from "components/atoms/FormHeader";
import FileWidget from "components/molecules/FileWidget";
import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useRouteLoaderData} from "react-router-dom";
import routes from "routes";
import {Socket} from "socket.io-client";
import {setPatientName} from "store/slices/patientInChatSlice";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../customHooks/reduxHooks";
import {Message} from "../../../pages/Chat/Chat";
import {setInfo} from "../../../store/slices/infoSlice";
import celebration from "../../../theme/svg/celebration.svg";
import {dateToShow} from "../../../utils/showDate";
import {trpc} from "../../../utils/trpc";
import Button from "../../atoms/Button";
import InfoBox from "../../atoms/InfoBox";
import Input from "../../atoms/Input";
import MessageWrapper from "../../atoms/MessageWrapper";
import AppointmentWidget from "../../molecules/AppointmentWidget";
import ModifyAppointmentForm from "../../molecules/ModifyAppointmentForm";
import PatientChatCard from "../../molecules/PatientChatCard";
import TextAreaInput from "../../molecules/TextAreaInput";
import AppointmentCalendar from "../../organisms/AppointmentCalendar";
import Modal from "../Modal";
import PatientDetail from "../PatientDetail";
import styles from "./ChatRoom.module.scss";
import { analyticsService } from "../../../utils/analyticsService";
import { raiseException } from "../../../utils/raiseException";
import * as R from "ramda";
import PatientPayment from "../PatientPayment"
import { currentUserLocationIsItaly, getNormalizedDate } from "../../../utils/dateTime";

interface Props {
	className?: string;
	chatId: string;
	user: any;
}

const ChatRoom = ({className = "", chatId, user}: Props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const socket = useRouteLoaderData("root") as Socket;

	// const myTherapist = useAppSelector((store) => store.myTherapist.myTherapist);
	const patientName = useAppSelector((store) => store.patientInChat);
	const onboarding = useAppSelector((store) => store.onBoardingDone);

	const [stringToSearch, setStringToSearch] = useState("");
	const [searchedMessages, setSearchedMessages] = useState<any[]>([]);
	const [previousMessageToggle, setPreviousMessagesToggle] = useState(false);
	const [scrollPreviousToggle, setScrollPreviousToggle] = useState(true);
	const [selectedPatientId, setSelectedPatientId] = useState("");
	const [modalIsVisible, setModalIsVisible] = useState(false);
	const [modalChildren, setModalChildren] = useState<JSX.Element | undefined>();
	const [enableScrollToBottom, setEnableScrollToBottom] = useState(true);
	const [message, setMessage] = useState("");
	const [oldMessageDate, setOldMessageDate] = useState("");
	const [lastMessageBtnIsVisible, setLastMessageBtnIsVisible] = useState(false);
	const [oldMessageDateIsVisible, setOldMessageDateIsVisible] = useState(false);
	const [messagesList, setMessagesList] = useState<Message[]>([]);
	const [typingStatus, setTypingStatus] = useState();
	const [therapistName, setTherapistName] = useState({name: "", surname: ""});
	const [messagesToggle, setMessagesToggle] = useState(false);
	const [chatsToggle, setChatsToggle] = useState(true);
	const [refreshNotificationDots, setRefresNotificationDots] = useState(false);
	const [cursor, setCursor] = useState<number | null>(null);
	const [chats, setChats] = useState<any>([]);
	const [patientChat, setPatientChat] = useState(chatId);
	const [actualChat, setActualChat] = useState<any>();
	const [patient, setPatient] = useState<any>(null);
	const [patientDetailIsOpen, setPatientDetailIsOpen] = useState(false);
	const [patientChatIsOpen, setPatientChatIsOpen] = useState(false);
	const [deletedPatient, setDeletedPatient] = useState("");
	const conversationBottomRef = useRef<null | HTMLDivElement>(null);
	const messageWrapperRef = useRef<HTMLDivElement[]>([]);
	const conversationTopRef = useRef<null | HTMLDivElement>(null);
  const [showGreyBlock, setShowGreyBlock] = useState(true);
	const showGreyBlockByUser = JSON.parse(localStorage.getItem('greyBlock') as any) || false;

	useEffect(() => {
		analyticsService({'event': 'virtualPageview', 'pageUrl': '/dashboard/chat', 'pageTitle': 'Chat'});
	}, []);

	const patientListItems = [
		{
			key: 1,
			children: (
				<div className={styles.patientCardsWrapper}>
					{chats
						?.filter((chat) => chat.patientStatus === "new_patient")
						.map((chat, index: number) => {
							return (
								<div
									key={index}
									onClick={() => {
										chatHandler(chat);
										setPatientChatIsOpen(true);
										setRefresNotificationDots(true);
										dispatch(
											setPatientName({
												name: chat?.patient?.given_name,
												surname: chat?.patient?.family_name,
												id: chat.patient?.id,
											})
										);
									}}
								>
									<PatientChatCard
										patientId={chat?.patient?.id}
										isSelected={selectedPatientId === chat?.patient?.id}
										patientName={
											chat.patient
												? `${chat.patient.given_name} ${chat.patient?.family_name}`
												: chat?.deletedPatientName
										}
										unreadMessages={chat.unreadMessages}
										lastMessage={chat.lastMessage}
										lastAppointment={chat.lastAppointment}
									/>
								</div>
							);
						})}
				</div>
			),
			label: "Nuovi pazienti",
		},
		{
			key: 2,
			children: (
				<div className={styles.patientCardsWrapper}>
					{chats
						?.filter((chat: any) => chat.patientStatus === "therapy")
						.map((chat: any, index: number) => {
							return (
								<div
									key={index}
									onClick={() => {
										chatHandler(chat);
										setPatientChatIsOpen(true);
										setRefresNotificationDots(true);
										dispatch(
											setPatientName({
												name: chat?.patient?.given_name,
												surname: chat?.patient?.family_name,
												id: chat.patient?.id,
											})
										);
									}}
								>
									<PatientChatCard
										patientId={chat?.patient?.id}
										isSelected={selectedPatientId === chat?.patient?.id}
										patientName={
											chat.patient
												? `${chat.patient.given_name} ${chat.patient?.family_name}`
												: chat?.deletedPatientName
										}
										unreadMessages={chat.unreadMessages}
										lastMessage={chat.lastMessage}
										lastAppointment={chat.lastAppointment}
									/>
								</div>
							);
						})}
				</div>
			),
			label: "In terapia",
		},
		{
			key: 3,
			children: (
				<div className={styles.patientCardsWrapper}>
					{chats
						?.filter((chat: any) => chat.patientStatus === "first_appointment")
						.map((chat: any, index: number) => {
							return (
								<div
									key={index}
									onClick={() => {
										chatHandler(chat);
										setPatientChatIsOpen(true);
										setRefresNotificationDots(true);
										dispatch(
											setPatientName({
												name: chat?.patient?.given_name,
												surname: chat?.patient?.family_name,
												id: chat.patient?.id,
											})
										);
									}}
								>
									<PatientChatCard
										patientId={chat?.patient?.id}
										isSelected={selectedPatientId === chat?.patient?.id}
										patientName={
											chat.patient
												? `${chat.patient.given_name} ${chat.patient?.family_name}`
												: chat?.deletedPatientName
										}
										unreadMessages={chat.unreadMessages}
										lastMessage={chat.lastMessage}
										lastAppointment={chat.lastAppointment}
									/>
								</div>
							);
						})}
				</div>
			),
			label: "Primo colloquio",
		},
		{
			key: 4,
			children: (
				<div className={styles.patientCardsWrapper}>
					{chats
						?.filter((chat: any) => chat.patientStatus === "inactive")
						.map((chat: any, index: number) => {
							return (
								<div
									key={index}
									onClick={() => {
										chatHandler(chat);
										setPatientChatIsOpen(true);
										setRefresNotificationDots(true);
										dispatch(
											setPatientName({
												name: chat?.patient?.given_name,
												surname: chat?.patient?.family_name,
												id: chat.patient?.id,
											})
										);
									}}
								>
									<PatientChatCard
										isSelected={
											chat?.deletedPatientName
												? chat?.id === actualChat?.id
												: selectedPatientId === chat?.patient?.id
										}
										patientName={
											chat.patient
												? `${chat.patient.given_name} ${chat.patient?.family_name}`
												: chat?.deletedPatientName
										}
										unreadMessages={chat.unreadMessages}
										lastMessage={chat.lastMessage}
										lastAppointment={chat.lastAppointment}
									/>
								</div>
							);
						})}
				</div>
			),
			label: "Inattivi",
		},
	];

	// const getNotification =
	//   trpc.mainService.notification.getNotifications.useQuery({
	//     type: "expired_appointment",
	//   }).data;

	const getMessages = trpc.mainService.chat.getChatMessages.useQuery(
		{
			chatId: patientChat,
			quantity: 50,
			cursor: cursor,
		},
		{enabled: !!(messagesToggle && patientChat)}
	);

	const readChatMessages = trpc.mainService.chat.readChatMessages.useMutation();

	const searchMessage = trpc.mainService.chat.searchMessages.useMutation();

	const previousMessage =
		trpc.mainService.chat.getChatPreviousMessages.useQuery(
			{
				chatId: patientChat,
				cursor: cursor!,
			},
			{
				enabled: previousMessageToggle,
			}
		);

	const getChats = trpc.mainService.chat.getChats.useQuery(undefined, {
		enabled: chatsToggle,
	});

	const getCurrentUserInfoResponse =
		trpc.mainService.user.getCurrentUserInfo.useQuery();

	const getinformedConsentResponse =
		trpc.mainService.patient.getinformedConsent.useQuery({
			patientId: selectedPatientId
	});

	const getPatient = trpc.mainService.therapist.getPatient.useQuery(
		{
			patientId: selectedPatientId,
		},
		{
			enabled: !!(
				getCurrentUserInfoResponse?.data?.user?.role === "therapist" &&
				selectedPatientId
			),
		}
	).data;

	const myTherapist = trpc.mainService.patient.getMyTherapist.useQuery(
		undefined,
		{
			enabled: !!(user.role === "patient"),
		}
	).data?.therapist;

	const viewInformedConsent = () => {
		window.open(`${routes.InformedConsentView.path.replace(/\/:[^\/]+$/, '')}/${patient.id}`, '_blank');
	}

	const textBeforeLink = "Ogni 15 giorni ricordati di impostare il tuo calendario per gli incontri gratuiti da ";
	const link = (
		<span
			onClick={() => {
				navigate(routes.Appointments.path, {
					state: {
						key: '3',
					},
				});
			}}
			className={styles.chatInfoConsent}
		>
			qui
		</span>
	);

	const errorHandler = (error: any) => {
		raiseException(error);
		dispatch(
			setInfo({
				title: error.data.code,
				text: error.message,
				variant: "error",
			})
		);
	};

	const chatHandler = async (actualChat: any) => {
		socket.emit("leave", patientChat);
		socket.emit("joinChat", actualChat.id);
		try {
			await readChatMessages.mutateAsync({
				chatId: actualChat.id,
			});
		} catch (e: any) {
			raiseException(e);
			console.error(e);
		}
		setChatsToggle(true);
		getChats.refetch();
		setPatientChat(actualChat.id);
		setCursor(null);
		setMessagesList([]);
		setMessagesToggle(true);
		setActualChat(actualChat);
		setSelectedPatientId(actualChat?.patient?.id);
		setPatientDetailIsOpen(false);
		setEnableScrollToBottom(true);
		if (actualChat?.deletedPatientName) {
			setDeletedPatient(actualChat.deletedPatientName);
		} else {
			setDeletedPatient("");
		}
	};

	const sendMessage = () => {
		socket.on("isTyping", (data) => {
			setTypingStatus(data);
		});
		if (message?.trim() !== "") {
			socket.emit(
				"sendTextMessage",
				{type: "text", content: message},
				patientChat
			);
		}
		setMessage("");
	};

	const scrollToBottom = () => {
		conversationBottomRef?.current?.scrollIntoView({
			inline: "end",
			block: "end",
		});
	};

	const scrollToTop = () => {
		conversationTopRef?.current?.scrollIntoView();
	};

	const checkScrollToBottom = (e: any) => {
		e.preventDefault();
		const target = e.target as HTMLElement;
		const sh = target.scrollHeight;
		const st = Math.floor(target.scrollTop);
		const oh = target.offsetHeight;
		const difference = sh - oh - st;
		showDateFirstMessage();
		loadOldMessages();
		if (!lastMessageBtnIsVisible && difference > 500) {
			setLastMessageBtnIsVisible(true);
		}
		if (!oldMessageDateIsVisible && difference > 100) {
			setOldMessageDateIsVisible(true);
		}
		if (
			(lastMessageBtnIsVisible || oldMessageDateIsVisible) &&
			difference < 2
		) {
			setLastMessageBtnIsVisible(false);
			setOldMessageDateIsVisible(false);
		}
	};

	const showDateFirstMessage = () => {
		if (messageWrapperRef.current.length === messagesList?.length) {
			const observer = new IntersectionObserver(
				(el) => {
					if (el.length === messagesList.length) {
						const topMessage = el.filter((i) => i.isIntersecting === true);
						const topMessageLenght = topMessage.length - 1;
						el.map((i: any, index) => {
							const dateString = messagesList[index]?.when;
							const date = new Date(dateString);
							if (
								index !== el.length - 1 &&
								topMessage[topMessageLenght] &&
								topMessage[topMessageLenght].target !== undefined &&
								topMessage[topMessageLenght].target === i.target &&
								oldMessageDateIsVisible &&
								oldMessageDate !== dateToShow(date, "chat")
							) {
								setOldMessageDate(dateToShow(date, "chat")!);
							}
						});
					}
				},
				{threshold: [0]}
			);
			messageWrapperRef.current.map((i, index) => {
				if (i) {
					observer.observe(i);
				}
			});
		}
	};

	const closeModal = () => {
		setModalIsVisible(false);
	};

	const loadOldMessages = () => {
		if (scrollPreviousToggle) {
			// if (messageWrapperRef.current.length === messagesList?.length) {
			const observer = new IntersectionObserver(
				(el) => {
					if (
						messagesList.length !== 0 &&
						messagesList.length === el.length &&
						el[el.length - 10] &&
						el[el.length - 10].isIntersecting
					) {
						setMessagesToggle(true);
					}
				},
				{threshold: [0]}
			);
			messageWrapperRef.current.map((i, index) => {
				if (i) {
					observer.observe(i);
				}
			});
			// }
		}
	};

	const setAppointment = () => {
		setModalIsVisible(true);
		setModalChildren(
			<AppointmentCalendar
				chatId={actualChat.id}
				patientId={actualChat.patient?.id}
				subtitle="
      Ricordati di concordare in anticipo con il paziente sulla base delle
      sue disponibilità."
				closeModal={() => setModalIsVisible(false)}
			/>
		);
	};

	const goToPatientDetail = () => {
		setPatientDetailIsOpen(true);
	};

	const onModify = (appointment: any, messageId: number) => {
		const sendModifyMessage = (modifyMessage: string) => {
			if (modifyMessage?.trim() !== "") {
				socket.emit(
					"sendTextMessage",
					{type: "text", content: modifyMessage},
					patientChat
				);
				setMessage("");
			}
		};
		const onCancel = () => {
			socket.emit("updateWidgetMessage", messageId, patientChat);
			setMessagesList(
				messagesList.map((i) => {
					if (i.appointmentId === appointment.id) {
						i.appointment.status = "cancelled";
					}
					return i;
				})
			);
		};
		const onMove = () => {
			socket.emit("updateWidgetMessage", messageId, patientChat);
			setMessagesList(
				messagesList.map((i) => {
					if (i.appointmentId === appointment.id) {
						i.appointment.status = "moved";
					}
					return i;
				})
			);
		};
		const onPatientRequestMove = () => {
			socket.emit("updateWidgetMessage", messageId, patientChat);
			setMessagesList(
				messagesList.map((i) => {
					// console.log("cerco l'appuntamento", appointment);
					if (i.appointmentId === appointment.id) {
						i.appointment.moveRequest = true;
					}
					return i;
				})
			);
		};
		setModalChildren(
			<ModifyAppointmentForm
				onPatientRequestMove={onPatientRequestMove}
				onCancel={onCancel}
				onMove={onMove}
				closeModal={closeModal}
				chatId={patientChat}
				sendModifyMessage={sendModifyMessage}
				setMessage={setMessage}
				patientId={actualChat?.patient?.id || user.id}
				appointment={appointment}
			/>
		);
		setModalIsVisible(true);
		// modifyAppointment.mutateAsync({ appointmentId: appointmentId }).then(data => console.log(data));
	};

	const acceptAppointment =
		trpc.mainService.appointment.acceptFreeAppointment.useMutation();
	const onAccept = (appointmentId: string, messageId: number) => {
		acceptAppointment
			.mutateAsync({appointmentId})
			.then((data) => {
				if (data.appointment) {
					const date = getNormalizedDate(data.appointment.startTime).toLocaleDateString(
						"default",
						{
							day: "2-digit",
							month: "long",
							year: "numeric",
						}
					);
					const time = getNormalizedDate(data.appointment.startTime).toLocaleString(
						"IT",
						{
							hour: "2-digit",
							minute: "2-digit",
						}
					);
					setModalChildren(
						<div className={styles.confirmAcceptMessage}>
							<FormHeader
								title="Appuntamento confermato"
								closeModal={() => {
									setModalIsVisible(false);
									setModalChildren(undefined);
								}}
								closeButton
							></FormHeader>
							Appuntamento del {date} alle ore {time} è stato confermato con
							successo
						</div>
					);
					setModalIsVisible(true);
					// dispatch(
					//   setInfo({
					//     title: data.message,
					//     text: `Appuntamento del ${date} è stato confermato con successo`,
					//     variant: "confirm",
					//   })
					// );
					socket.emit("updateWidgetMessage", messageId, patientChat);
					// messagesList.map((i) => {
					//   if (i.appointmentId === appointmentId) {
					//     i.appointment = data.appointment;
					//   }
					//   return i;
					// });
				}
			})
			.catch((e) => {
				errorHandler(e);
			});
	};

	const getPaymentIntent =
		trpc.mainService.payments.stripe.getPaymentIntent.useMutation();

	// const onPayment = async (id: string, messageId: number) => {
	// 	console.log('test')
	// 	const onPayAppointment = async () => {
	// 		socket.emit("updateWidgetMessage", messageId, patientChat);
	// 		// messagesList.map((i) => {
	// 		//   if (i.appointmentId === id) {
	// 		//     i.appointment.status = "ready";
	// 		//   }
	// 		//   return i;
	// 		// });
	// 		// setModalIsVisible(false);
	// 		// setModalChildren(<div></div>);
	// 	};
	// 	const response = await getPaymentIntent.mutateAsync({
	// 		appointmentId: id,
	// 	});
	//
	// 	setModalChildren(
	// 		<PaymentForm
	// 			closeModal={closeModal}
	// 			savedPayment={
	// 				getCurrentUserInfoResponse.data?.user?.stripeDefaultPaymentMethodId ??
	// 				undefined
	// 			}
	// 			setupFutureUsage={Boolean(response.setupFutureUsage)}
	// 			onPayAppointment={onPayAppointment}
	// 			paymentIntentId={response.paymentIntentId}
	// 			clientSecret={response.clientSecret!}
	// 			amount={response.amount / 100}
	// 			appointmentId={id}
	// 		/>
	// 	);
	// 	setModalIsVisible(true);
	// };


	// const onPaymentForm = () => {
	// 	console.log('onPaymentForm')
	//
	// 	setModalChildren(
	// 		<UserPaymentMethodTable
	// 			isModal={'widget'}
	// 			closeModal={closeModal}
	// 			setIsPaymentFormSuccess={setIsPaymentFormSuccess}
	// 			userInfo={getCurrentUserInfoResponse}
	// 		/>
	// 	)
	// 	setModalIsVisible(true);
	// }


	const onPatientPayment = async (appointment: any, messageId: number) => {
		const onPayAppointment = async () => {
			socket.emit("updateWidgetMessage", messageId, patientChat);
			// messagesList.map((i) => {
			//   if (i.appointmentId === id) {
			//     i.appointment.status = "ready";
			//   }
			//   return i;
			// });
			// setModalIsVisible(false);
			// setModalChildren(<div></div>);
		};
		const response = await getPaymentIntent.mutateAsync({
			appointmentId: appointment.id,
		});

		setModalChildren(
			<PatientPayment
				isModal
				closeModal={closeModal}
				userInfo={getCurrentUserInfoResponse}
				myTherapist={myTherapist}
				savedPayment={
					getCurrentUserInfoResponse.data?.user?.stripeDefaultPaymentMethodId ??
					undefined
				}
				setupFutureUsage={Boolean(response.setupFutureUsage)}
				onPayAppointment={onPayAppointment}
				paymentIntentId={response.paymentIntentId}
				clientSecret={response.clientSecret!}
				amount={response.amount / 100}
				appointment={appointment}
			/>
		)
		setModalIsVisible(true);
	}

	const onReady = (id: string, isFirstAppointment: string) => {
		// navigate(`/seduta/${id}`);
		const sessionType = isFirstAppointment ? 'free' : 'paid';
		const userRole = user.role === 'therapist' ? 'therapist' : 'patient';
		const event = `${sessionType}_session_room_${userRole}`;

		analyticsService({ event, appointmentID: id });

		window.open(`/${routes.WaitingRoom.path.split("/")[1]}/${id}`, "_blank");
	};

	useEffect(() => {
		if (!patientDetailIsOpen) {
			scrollToBottom();
		}
	}, [patientDetailIsOpen]);

	useEffect(() => {
		const onMessage = (message) => {
			if (message.chatId !== patientChat) {
				return;
			}
			if (
				(user.role === "therapist" &&
					(message.from === selectedPatientId || message.from === user.id)) ||
				user.role === "patient"
			) {
				setMessagesList(R.uniq([message, ...messagesList]).sort((a, b) => b.id - a.id));
				setEnableScrollToBottom(true);
				setShowGreyBlock(false)
				localStorage.setItem('greyBlock', JSON.stringify(true));

			}
		};
		const onUpdatedWidgetMessage = (widgetMessage) => {
			if (widgetMessage.chatId !== patientChat) {
				return;
			}
			messagesList.map((message) => {
				if (message.id === widgetMessage.id) {
					message.appointment.status = widgetMessage.appointment.status;
					message.appointment.moveRequest =
						widgetMessage.appointment.moveRequest;
					message.appointment.cancelledBy =
						widgetMessage.appointment.cancelledBy;
				}
				return message;
			});
		};
		socket.on("message", onMessage);
		socket.on("updatedWidgetMessage", onUpdatedWidgetMessage);

		enableScrollToBottom && scrollToBottom();

		return () => {
			socket.off("message", onMessage);
			socket.off("updatedWidgetMessage", onUpdatedWidgetMessage);
		};
	}, [socket, messagesList, enableScrollToBottom]);

	useEffect(() => {
		const onVisibilityChange = async () => {
			if (document.visibilityState === "visible") {
				if (patientChat) {
					await readChatMessages.mutateAsync({
						chatId: patientChat,
					});
					setChatsToggle(true);
					await getChats.refetch();
				}
			}
		};
		document.addEventListener("visibilitychange", onVisibilityChange);
		return () => {
			document.removeEventListener("visibilitychange", onVisibilityChange);
		};
	}, [patientChat]);

	useEffect(() => {
		const onNotification = async (message) => {
			if (message?.chatId === patientChat) {
				try {
					if (document.visibilityState === "visible") {
						await readChatMessages.mutateAsync({
							chatId: patientChat,
						});
					}
				} catch (e: any) {
					raiseException(e);
					console.error(e);
				}
			}

			setChatsToggle(true);
			const fetchedChats = await getChats.refetch();

			if (message.changePatient) {
				const currentChat = fetchedChats?.data?.chats?.find(
					(chat) => chat.id === patientChat
				);
				setActualChat(currentChat);
			}
		};
		socket.on("notification", onNotification);
		return () => {
			socket.off("notification", onNotification);
		};
	}, [patientChat]);

	useEffect(() => {
		const reconnectToChat = () => {
			if (patientChat) {
				socket.emit("joinChat", patientChat);
			}
		};
		socket.on("connect", reconnectToChat);

		return () => {
			socket.off("connect", reconnectToChat);
		};
	}, [patientChat, socket]);

	useEffect(() => {
		if (actualChat && user.role === "therapist") {
			socket.emit("leave", patientChat);
			socket.emit("joinChat", actualChat.id);
		}
	}, [socket, actualChat, patientChat]);

	// useEffect(() => {
	//   if (conversation) {
	//     console.log("imposto in conversation");
	//     setCursor(conversation.cursor);
	//     setMessagesList(conversation.messages);
	//   }
	// }, [socket, conversation]);

	useEffect(() => {
		if (messagesToggle && patientChat) {
			// setModalIsVisible(true);
			// setModalChildren(<div>Loading</div>);
			if (!getMessages.isFetching && getMessages.status === "success") {
				if (getMessages.data.cursor === null) {
					setMessagesToggle(false);
					// setModalIsVisible(false);
				} else {
					setMessagesToggle(false);
					// setModalIsVisible(false);
					setMessagesList(R.uniq([...messagesList, ...getMessages.data.messages]).sort((a, b) => b.id - a.id));
					if (getMessages.data.messages.length > 0) {
						setShowGreyBlock(false)
						localStorage.setItem('greyBlock', JSON.stringify(true));
					}
					setCursor(getMessages.data.cursor);
					messagesList.length >= 20 && setEnableScrollToBottom(false);
				}
			}
			if (getMessages.status === "error") {
				setMessagesToggle(false);
				// setModalIsVisible(false);
				dispatch(
					setInfo({text: getMessages.error.message, variant: "error"})
				);
			}
			// getChats.refetch();
			// setChatsToggle(true);
		}
	}, [getMessages, messagesToggle, patientChat]);

	const showInformModal = (appointment: any, messageId: number) => {
		setModalChildren(
			<div className={styles.confirmAcceptMessage}>
				<p className={styles.firstAppointmentTitleSize}>
					Non è possibile creare un’altra sessione, hai già una sessione gratuita programmata con questo paziente, puoi aggiornare quella.
				</p>
				<div className={styles.firstAppointmentButtons}>
					<Button className={styles.space} onClick={() => {
						setModalIsVisible(false);
						setModalChildren(undefined);
					}}>
						Annulla
					</Button>
					<Button onClick={() => onModify(appointment, messageId)}>Sposta</Button>
				</div>
			</div>
		);
		setModalIsVisible(true);
	}

	// useEffect(() => { TODO: feature non ancora richiesta
	//   socket.on("isTyping", (data) => {
	//     setTypingStatus(data);
	//   });
	//   setTimeout(() => {
	//     setTypingStatus(undefined);
	//   }, 3000);
	// }, [socket, typingStatus]);

	useEffect(() => {
		if (myTherapist) {
			const {given_name, family_name} = myTherapist;
			setTherapistName({name: given_name, surname: family_name});
		}
	}, [myTherapist]);

	useEffect(() => {
		if (patientChat) {
			setMessagesToggle(true);
		}
	}, [patientChat]);
	// useEffect(() => { TODO: non mostreremo più la notifica di appuntamento scaduto?
	//   if (getNotification && getNotification.notifications.length > 0) {
	//     const { content, id } = getNotification.notifications[0];
	//     setModalIsVisible(true);
	//     setModalChildren(
	//       <AppointmentCancelled
	//         closeModal={closeModal}
	//         notificationId={id}
	//         content={content}
	//       />
	//     );
	//   }
	// }, [getNotification]);

	useEffect(() => {
		if (chatsToggle) {
			// setModalIsVisible(true);
			// setModalChildren(<div>Loading</div>);
			if (!getChats.isFetching && getChats.status === "success") {
				if (refreshNotificationDots) {
					setChatsToggle(false);
					setRefresNotificationDots(false);
					setChats(getChats.data.chats);
					return;
				}
				setChatsToggle(false);
				// setModalIsVisible(false);
				setChats(getChats.data.chats);
				// setActualChat(getChats.data.chats[0] as any);
			}
			if (getChats.status === "error") {
				setChatsToggle(false);
				// setModalIsVisible(false);
				dispatch(setInfo({text: getChats.error.message, variant: "error"}));
			}
		}
	}, [getChats, chatsToggle]);

	// useEffect(() => {
	//   console.log("cambiato patient chat");
	//   setPatientChat(chatId);
	// }, [chatId]);

	// useEffect(() => {
	//   const chatInStore = localStorage.getItem("storedChat");
	//   if (chatInStore && user.role === "therapist") {
	//     const chatObject = JSON.parse(chatInStore);
	//     chatHandler(chatObject);
	//     dispatch(
	//       setPatientName({
	//         name: chatObject?.patient?.given_name,
	//         surname: chatObject?.patient?.family_name,
	//         id: chatObject.patient?.id,
	//       })
	//     );
	//   }
	// }, [chats]);

	useEffect(() => {
		if (patientName.name === "") {
			setPatientChatIsOpen(false);
		} else {
			setMessage("");
			const actualChat = getChats.data?.chats.find(
				(c) => c.patient?.id === patientName.id
			);
			// actualChat && setSelectedPatientId(actualChat?.id);
			actualChat && chatHandler(actualChat);
			setPatientChatIsOpen(true);
			// setActualChat(actualChat);
			// setPatientChat(actualChat?.id!);
		}
	}, [patientName]);

	useEffect(() => {
		if (getPatient) {
			setPatient(getPatient.patient);
		}
	}, [getPatient]);

	// useEffect(() => {
	//   if (stringToSearch.trim() === "") {
	//     setSearchedMessages([]);
	//   } else {
	//     searchMessage
	//       .mutateAsync({ message: stringToSearch })
	//       .then((data) => {
	//         setSearchedMessages(data.messages);
	//       })
	//       .catch((e) => console.error("ERROR", e.shape.message));
	//   }
	// }, [stringToSearch]);

	useEffect(() => {
		if (previousMessage.isSuccess && previousMessageToggle) {
			setMessagesList(previousMessage.data.messages);
			setPreviousMessagesToggle(false);
			setMessagesToggle(false);
			setEnableScrollToBottom(false);
			setScrollPreviousToggle(false);
		}
	}, [previousMessage, previousMessageToggle]);

	useEffect(() => {
		if (!scrollPreviousToggle && messagesList.length > 0) {
			scrollToTop();
			setTimeout(() => {
				setScrollPreviousToggle(true);
			}, 1500);
		}
	}, [messagesList, scrollPreviousToggle]);

  const CurrentUserLocation: any = () => {

    return (
      !currentUserLocationIsItaly() &&
      <InfoBox
        className={styles.chatInfo}
        variant="warn"
        text={
          <span>Ricorda che l'orario, in ogni nostra comunicazione, si riferisce al fuso orario italiano</span>}
      />
    )
  }

	return (
		<div className={`${styles.chatWrapper} ${className}`}>
			{modalIsVisible && <Modal className={styles.modal}>{modalChildren}</Modal>}
			{onboarding.checkpoint && <Modal></Modal>}
			{user?.role === "therapist" && (
				<div
					className={`${styles.patientList} ${
						patientChatIsOpen && styles.hideList
					}`}
				>
					<Input
						placeholder="Cerca messaggio"
						className={styles.patientInputSearch}
						value={stringToSearch}
						rightIcon={
							<XMarkIcon
								onClick={() => {
									setStringToSearch("");
									setSearchedMessages([]);
								}}
							/>
						}
						onChange={(e) => {
							setStringToSearch(e.target.value);
							if (e.target.value?.trim() === "") {
								setSearchedMessages([]);
							} else {
								searchMessage
									.mutateAsync({message: e.target.value})
									.then((data) => {
										setSearchedMessages(data.messages);
									})
									.catch((e) => {
										raiseException(e);
										console.error("ERROR", e?.shape?.message)
									});
							}
						}}
					/>
					{stringToSearch?.trim() !== "" ? (
						<div className={styles.searchedMessagesWrapper}>
							{searchedMessages.map((m, index) => {
								const patient =
									m.chat.patient?.given_name +
									" " +
									m.chat.patient?.family_name;
								const messageDate = m.when;
								const messageContent = m.content;
								return (
									<div
										key={index}
										onClick={() => {
											socket.emit("leave", patientChat);
											socket.emit("joinChat", m.chat.id);
											setPatientChat(m.chatId);
											setCursor(m.id);
											setPreviousMessagesToggle(true);
											setActualChat(m.chat);
											setSelectedPatientId(m.chat?.patientId);
											setPatientDetailIsOpen(false);
											setRefresNotificationDots(true);
											setPatientChatIsOpen(true);
											dispatch(
												setPatientName({
													name: m.chat?.patient?.given_name,
													surname: m.chat?.patient?.family_name,
													id: m.chat.patient?.id,
												})
											);
										}}
									>
										<PatientChatCard
											lastMessage={{
												when: messageDate,
												content: messageContent,
											}}
											patientName={patient}
											unreadMessages={0}
											lastAppointment={null}
										/>
									</div>
								);
							})}
						</div>
					) : (
						<div>
							<Collapse
								bordered={false}
								expandIconPosition={"end"}
								items={patientListItems}
								defaultActiveKey={Array.from(
									{length: patientListItems.length},
									(_, i) => i + 1
								)}
							></Collapse>
						</div>
					)}
				</div>
			)}

			{(() => {
				// getMessages

				if (
					selectedPatientId ||
					(deletedPatient && patientChat) ||
					user.role === "patient"
				) {
					return (
						<div
							className={`${styles.chat} ${
								!patientChatIsOpen &&
								user?.role === "therapist" &&
								styles.hideChat
							}`}
						>
							{messagesToggle && (
								<div
									className={`${styles.loadingChat} ${
										user.role === "patient" ? styles.loadingChatPatient : ""
									}`}
								>
									<Spin
										indicator={
											<LoadingOutlined
												style={{fontSize: 15, color: "#2C2C4B"}}
												spin
											/>
										}
									/>
								</div>
							)}
							{user?.role === "therapist" && (
								<div>
									<div className={styles.headerTherapistChat}>
										<div className={styles.headerTherapistChatInfo}>
											<div className={styles.headerTherapistChatName}>
												{patientDetailIsOpen && (
													<ArrowLeftIcon
														className={styles.arrowIcon}
														onClick={() => setPatientDetailIsOpen(false)}
													/>
												)}
												{actualChat?.patient?.given_name}{" "}
												{actualChat?.patient?.family_name}
											</div>
											{actualChat?.lastAppointment && (
												<div
													className={styles.headerTherapistChatLastAppointment}
												>
													{<CalendarDaysIcon className={styles.icon}/>}
													<span>
                            ULTIMO APPUNTAMENTO{" "}
														{dateToShow(
															new Date(actualChat?.lastAppointment?.startTime),
															"card"
														)}
                          </span>
												</div>
											)}
										</div>
										<div className={styles.headerTherapistChatAction}>
											{/* TODO: feature da implementrare in un secondo momento?*/}
											{/* <div className={styles.headerTherapistChatNote}>
            <ClipboardDocumentIcon className={styles.icon} />
            <span>Note</span>
          </div> */}
											{actualChat?.active &&
												patientChat &&
												!patientDetailIsOpen && (
													<div className={styles.headerTherapistChatButtonWrapper}>
														<Button
															disabled={(getPatient?.patient.therapyType === 'single' && !getinformedConsentResponse?.data?.informedConsent) ||
																(getPatient?.patient.therapyType === 'underage' && !(getinformedConsentResponse?.data?.otherInformedConsentParent && getinformedConsentResponse?.data?.otherInformedConsent && getinformedConsentResponse?.data?.informedConsent)) ||
																(getPatient?.patient.therapyType === 'couple' && !getinformedConsentResponse?.data?.informedConsent)
															}
															className={styles.headerTherapistChatDetails}
															variant="secondary"
															onClick={() => viewInformedConsent()}
														>
															Visualizza il consenso informato
														</Button>
													<Button
														className={styles.headerTherapistChatDetails}
														variant="secondary"
														onClick={() => goToPatientDetail()}
													>
														Vai al dettaglio
													</Button>
													</div>
												)}
										</div>
									</div>
									<div className={styles.therapistBannerContainer}>
                    <CurrentUserLocation/>
										{!patient?.informedConsent && (
											<InfoBox
												className={styles.chatInfo}
												title={"Questa è la vostra chat privata"}
												text={`
                        ${
													patient?.therapyType === "single"
														? "Ricorda al paziente di firmare il consenso informato prima di iniziare le sedute di terapia."
														: ""
												}
                        ${
													patient?.therapyType === "couple"
														? "Ricorda al paziente di firmare il consenso informato prima di iniziare le sedute di terapia. Per la coppia è necessario il consenso di entrambi i partner."
														: ""
												}
                        ${
													patient?.therapyType === "underage"
														? "Ricorda al paziente di firmare il consenso informato prima di iniziare il colloquio gratuito e le sedute di terapia. Per il minore è necessario il consenso di entrambi i genitori."
														: ""
												}`}
											/>
										)}
										{user?.role === "therapist" && (
											<InfoBox
												className={styles.chatInfo}
												text={<>{textBeforeLink}{link}</>}

											/>
										)}
									</div>
								</div>
							)}
							{user?.role === "patient" && (
								<>
									{(showGreyBlock && !showGreyBlockByUser) && (
										<InfoBox
											className={styles.chatInfo}
											closeButton
											closeModal={() => {
												setShowGreyBlock(false)
												localStorage.setItem('greyBlock', JSON.stringify(true));
											}}
											title={`Ciao, ${chats[0]?.patient.given_name}`}
											text={
												<>
													<span>Siamo felici di presentarti il terapeuta che abbiamo selezionato appositamente per te:</span>
													<div style={{fontWeight: '600'}}>{therapistName?.name} {therapistName?.surname}</div>
													<div style={{fontWeight: '600'}}>{myTherapist?.title}</div>
													<span>Ti invitiamo a inviare un messaggio in chat indicando i giorni e gli orari in cui sei disponibile per il colloquio gratuito. Sarà un'opportunità per raccontarti meglio e porre tutte le domande che desideri</span>
												</>
											}
										/>
									)}
									{((!getCurrentUserInfoResponse.data?.user?.informedConsent && (!showGreyBlock || showGreyBlockByUser))) && (
										<InfoBox
											className={styles.chatInfo}
											variant="warn"
											text={
												user?.therapyType === "underage" &&
												getinformedConsentResponse.data?.informedConsent &&
												!getinformedConsentResponse.data
													?.otherInformedConsentParent ? (
													<>
                            <span>
                              Hai compilato correttamente il consenso informato.
                              Per poter partecipare alle sedute il consenso deve
                              essere firmato anche dall'altro genitore, tramite
                              il link ricevuto sulla sua casella postale. Se non
                              hai ricevuto il link, puoi richiederne un altro
                              attraverso
                            </span>{" "}
														<span
															onClick={() => {
																navigate(
																	routes.ProfileSection.links.preferences
																);
															}}
															className={styles.chatInfoConsent}
														>
                              questa pagina.
                            </span>
													</>
												) : (
													<span>
                            Per proseguire è necessario compilare il consenso
                            informato.{" "}
														<span
															onClick={() => {
																getinformedConsentResponse
																	.refetch()
																	.then((response) => {
																		if (!response.data?.informedConsent) {
																			navigate(
																				routes.ProfileSection.links.preferences,
																				{
																					state: {
																						informedConsentIsOpen: true,
																					},
																				}
																			);
																		} else {
																			navigate(
																				routes.ProfileSection.links.preferences
																			);
																		}
																	})
																	.catch((e) => {
																		raiseException(e);
																		navigate(
																			routes.ProfileSection.links.preferences
																		);
																	});
															}}
															className={styles.chatInfoConsent}
														>
                              Clicca qui
                            </span>{" "}
														per compilarlo in pochi semplici passi
                          </span>
												)
											}
										/>
									)}
                  <CurrentUserLocation/>
								</>
							)}
							{user?.role === "therapist" && (
								<span>
                  {!actualChat?.active && (
	                  <InfoBox
		                  className={styles.chatInfo}
		                  title={
			                  actualChat?.patient
				                  ? `${actualChat?.patient?.given_name} ${actualChat?.patient?.family_name} non è più un tuo paziente`
				                  : `${actualChat?.deletedPatientName} ha eliminato il suo profilo`
		                  }
		                  text={
			                  actualChat?.patient
				                  ? `${actualChat?.patient?.given_name} ${actualChat?.patient?.family_name} ha deciso di cambiare terapeuta.`
				                  : `${actualChat?.deletedPatientName} ha deciso di eliminare il suo profilo`
		                  }
	                  />
                  )}
                </span>
							)}

							{user?.role === "therapist" && patientDetailIsOpen ? (
								<PatientDetail
									patient={patient}
									onGoToChat={() => setPatientDetailIsOpen(false)}
								/>
							) : (
								<div
									data-test="messagesWrapper"
									className={styles.conversation}
									onScroll={(e) => {
										checkScrollToBottom(e);
									}}
								>
									{/* TODO: feature non ancora richiesta
    <OldMessageLabel
      isVisible={oldMessageDateIsVisible}
      date={oldMessageDate}
    /> */}
									<div ref={conversationTopRef}></div>
									{messagesList.length > 0
										? messagesList
											?.map((i: any, index: number) => {
												const sender = i.from === user?.id ? "me" : "other";
												const date = new Date(i.when);
												const previousDate = new Date(
													messagesList[index + 1]?.when
												);
												const hour = `${getNormalizedDate(i.when).toLocaleTimeString("it", {
													hour: "2-digit",
													minute: "2-digit",
												})}`;

												if (i.type === "text") {
													if (
														index > -1 &&
														(date.getDate() !== previousDate.getDate() ||
															date.getMonth() !== previousDate.getMonth() ||
															date.getFullYear() !==
															previousDate.getFullYear())
													) {
														return (
															<div
																id={i?.id}
																key={index}
																ref={(el: any) => {
																	if (messageWrapperRef.current !== null) {
																		messageWrapperRef.current[index] = el!;
																	}
																}}
															>
																<MessageWrapper
																	dateIsVisible={true}
																	sender={sender}
																	text={i.content}
																	date={dateToShow(date, "chat")!}
																	hour={hour}
																/>
															</div>
														);
													} else {
														return (
															<div
																id={i?.id}
																key={index}
																ref={(el: any) => {
																	if (messageWrapperRef.current !== null) {
																		messageWrapperRef.current[index] = el!;
																	}
																}}
															>
																<MessageWrapper
																	dateIsVisible={false}
																	sender={sender}
																	text={i.content}
																	date={dateToShow(date, "chat")!}
																	hour={hour}
																/>
															</div>
														);
													}
												} else if (i.type === "widget") {
													const {appointment} = i;
													const {
														startTime,
														endTime,
														status,
														id,
														isFirstAppointment,
														moveRequest,
														createdByPatient,
													} = appointment;

													return (
														<div
															id={i?.id}
															key={index}
															ref={(el: any) => {
																if (messageWrapperRef.current !== null) {
																	messageWrapperRef.current[index] = el!;
																}
															}}
														>
															<MessageWrapper
																isWidget={true}
																dateIsVisible={false}
																sender={sender}
																text={
																	<div>
																		{status === "waiting_for_confirm" &&
																			+getNormalizedDate(startTime) > +getNormalizedDate() && (
																				<div
																					className={
																						styles.infoNewAppointment
																					}
																				>
																					<img
																						className={styles.icon}
																						src={celebration}
																						alt="celebration icon"
																					/>
																					Nuova proposta di appuntamento
																				</div>
																			)}
																		<AppointmentWidget
																			messageId={i.id}
																			isFirstAppointment={isFirstAppointment}
																			createdByPatient={createdByPatient}
																			userRole={user?.role}
																			onModify={onModify}
																			onAccept={onAccept}
																			onPatientPayment={() => onPatientPayment(appointment, i.id)}
																			onReady={() => onReady(appointment.id, isFirstAppointment)}
																			appointment={appointment}
																			endTime={endTime}
																			widgetStatus={status}
																			startTime={startTime}
																		/>
																	</div>
																}
																date={dateToShow(date, "chat")!}
																hour={hour}
															/>
														</div>
													);
												} else if (i.type === "file") {
													return (
														<div
															id={i?.id}
															key={index}
															ref={(el: any) => {
																if (messageWrapperRef.current !== null) {
																	messageWrapperRef.current[index] = el!;
																}
															}}
														>
															<MessageWrapper
																isWidget={true}
																dateIsVisible={false}
																sender={sender}
																text={
																	<div>
																		<FileWidget
																			filename={i.filename}
																			messageId={i.id}
																		/>
																	</div>
																}
																date={dateToShow(date, "chat")!}
																hour={hour}
															/>
														</div>
													);
												}
											})
											.reverse()
										: !messagesToggle && (
										<div className={styles.emptyChat}>
											{actualChat?.deletedPatientName ? 'Il paziente ha deciso di eliminare il suo profilo' : 'Non ci sono ancora messaggi'}
										</div>
									)}
									<div ref={conversationBottomRef}></div>
								</div>
							)}
							{/*
  TODO: feature non ancora richiesta
   {typingStatus ? <div>{typingStatus}</div> : null}  */}
							{(!patientDetailIsOpen && !actualChat?.deletedPatientName) && (
								<TextAreaInput
									messagesList={messagesList}
									oldMessageDateIsVisible={oldMessageDateIsVisible}
									scrollToBottom={scrollToBottom}
									sendMessage={sendMessage}
									value={message}
									onChange={setMessage}
									chatId={patientChat}
									appointmentAction={setAppointment}
									onModify={showInformModal}
									// TODO: portare la funzione setAppointment nella scheda appuntamenti del professionista
									appointmentIcon={user?.role === "therapist"}
									checkIfTyping={() => {
										// socket.emit("typing", user.id, otherUser?.id);
									}}
								/>
							)}
						</div>
					);
				} else {
					return (
						<div
							className={`${styles.chat} ${
								!patientChatIsOpen &&
								user?.role === "therapist" &&
								styles.hideChat
							}`}
						>
							<div className={styles.emptyChat}>Selezionare un paziente</div>
						</div>
					);
				}
			})()}
		</div>
	);
};
export default ChatRoom;
